import {
  CreateMagicLinkDocument,
  CreateMagicLinkMutation,
  CreateMagicLinkMutationVariables,
  Error,
  LoginContent,
  getLocalizedContent,
} from "@onzeit/shared";
import { useActionData, useParams } from "@remix-run/react";
import { ActionFunctionArgs, json, redirect } from "@remix-run/server-runtime";
import { Login } from "~/components/frontend/Login";
import { getApolloClient } from "~/utils/apolloClient";
// @ts-ignore

export const action = async ({ request }: ActionFunctionArgs) => {
  const body = await request.formData();
  const email = body.get("email");

  try {
    const apolloClient = getApolloClient();
    const result = await apolloClient.mutate<
      CreateMagicLinkMutation,
      CreateMagicLinkMutationVariables
    >({
      mutation: CreateMagicLinkDocument,
      variables: {
        input: {
          emailAddress: email?.toString()!,
          locale: "de",
        },
      },
    });

    // check if device already has a device cookie
    // const cookieHeader = request.headers.get("Cookie");
    // const cookie = (await userDeviceIdCookie.parse(cookieHeader)) || {};
    // if (!cookie.device_id) {
    //   let parser = new UAParser(request.headers.get("user-agent")); // you need to pass the user-agent for nodejs
    //   console.log(parser); // {}
    //   let parserResults = parser.getResult();
    //   console.log(parserResults);

    //   cookie.device_id = uuidv4();
    // }

    if (result.data?.createMagicLink?.success) {
      // console.log(cookie);
      return redirect(
        `/app/login/${result.data?.createMagicLink?.guid}/${email}`,
        // {
        //   headers: {
        //     "Set-Cookie": await userDeviceIdCookie.serialize(cookie),
        //   },
        // },
      );
    }

    return json({ errors: result.data?.createMagicLink?.errors });
  } catch (e) {
    return json({
      errors: [
        {
          message:
            "Es ist ein unvorhersehbarer Fehler aufgetreten. Wir kümmern uns darum. Probieren Sie es später noch einmal.",
        },
      ],
    });
  }
};

export default () => {
  const { locale } = useParams();
  const content = getLocalizedContent(locale!, LoginContent);
  const actionData = useActionData<typeof action>();

  return (
    <Login
      content={content}
      locale={locale!}
      errors={actionData?.errors as Error[]}
    />
  );
};
